
import './App.css';
import Index from './components/Index'
function App() {
  return (
    <div >
     <Index />
    </div>
  );
}

export default App;
